import type { Schemas } from "#shopware";

export const useActivePage = createSharedComposable(() => {
  const pageType = ref();
  const isHomePage = ref(false);

  const setPageType = (category: Schemas["Category"]) => {
    isHomePage.value = category?.parentId === null;
    pageType.value = category?.cmsPage?.type;
  };

  return {
    pageType,
    isHomePage,
    setPageType,
  };
});
